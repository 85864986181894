import React, { useState, useEffect } from 'react';
import { Carousel } from 'react-responsive-carousel';

import "react-responsive-carousel/lib/styles/carousel.min.css";
import "./Carousel.scss";

import kitchen from '../../images/gallery/kitchen.jpg';
import planters from '../../images/gallery/planters.jpg';
import shoerack from '../../images/gallery/shoerack.jpg';
import blacktable2 from '../../images/gallery/blacktable4.jpg';
import tvDoors from '../../images/gallery/tvDoors.jpg';

//Listen for screen size changes...
const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

function useCurrentWidth() {
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    let timeoutId:number|undefined = undefined;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => setWidth(getWidth()), 10);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return width;
}

const slides = [
    <img src={kitchen} alt="Kitchen"/>,
    <img src={planters} alt="Planter Boxes"/>,
    <img src={blacktable2} alt="Black Table"/>,
    <img src={tvDoors} alt="TV Doors"/>,
    <img src={shoerack} alt="Shoerack"/>,
]

function DemoCarousel() {
    let width = useCurrentWidth();
    const [currentIndex, setCurrentIndex] = useState(0)

    let slidePercentage = 34;
    if(width <= 600) {
        slidePercentage = 100;
    } else if (width <= 1000) {
        slidePercentage = 50;
    } else {
        slidePercentage = 34;
    }

    return (   
        <div className="carouselWrapper">
            <div id="leftControlButton">
                <button 
                    id="leftControl" 
                    className="arrowContainer" 
                    title='previous slide' 
                    type="button" 
                    onClick={() => {
                        if(width <= 600) {
                            if (currentIndex !== 0) {
                                setCurrentIndex(currentIndex - 1);
                            }
                        } else if (width <= 1000) {
                            if (currentIndex !== 0) {
                                setCurrentIndex(currentIndex - 1);
                            }
                        } else {
                            if (currentIndex !== 1) {
                                setCurrentIndex(currentIndex - 1);
                            }
                        }
                    }
                    } 
                    >
                    <i className='arrow left'></i>
                </button>
            </div>
            <Carousel 
                className="mainCarousel"
                centerMode 
                centerSlidePercentage={slidePercentage} 
                selectedItem={currentIndex}
                showStatus= {false}
                showThumbs={false}
                autoPlay={false}
                dynamicHeight={false}
                showIndicators={false}
                showArrows={false}
                swipeable={true}
                emulateTouch={true}
                transitionTime={300}
                >
                    {
                        slides.map((x, index) => {
                            return (
                                <div key={index} className='slideContainer'>
                                    {x}
                                    {/* <p>{index}</p> */}
                                </div>
                            );
                        })
                    }
            </Carousel>
            <div id="rightControlButton">
                <button 
                    id="rightControl" 
                    className="arrowContainer" 
                    title='next slide' 
                    type="button" 
                    onClick={() => {
                        if(width <= 600) {
                            if (currentIndex !== (slides.length - 1)){
                                setCurrentIndex(currentIndex + 1);
                            }
                        } else if (width <= 1000) {
                            if (currentIndex !== (slides.length - 1)){
                                setCurrentIndex(currentIndex + 1);
                            }
                            if (currentIndex === 0) {
                                setCurrentIndex(currentIndex + 2);
                            }
                        } else {
                            if (currentIndex !== (slides.length - 2)){
                                setCurrentIndex(currentIndex + 1);
                            }
                            if (currentIndex === 0) {
                                setCurrentIndex(currentIndex + 2);
                            }
                        }
                    }
                    } 
                    >
                    <i className='arrow right'></i>
                </button>
            </div>
        </div>
    );
}

export default DemoCarousel;