import React from 'react';
import { Link } from 'react-router-dom';

import Logo from '../../images/logo/icon_white_cropped.png'

import './SideDrawer.scss';

interface Props {
    show: boolean,
    clickHandler: (() => void);
}

function SideDrawer(props:Props) {
    return (
        <nav className={"sideDrawer " + (props.show ? 'open' : '')}>
            <div className="navbarIcon">
                <Link to="/" onClick={props.clickHandler}>
                    <img src={Logo} alt="Logo" width="100" />
                </Link>
            </div>
            <ul>
                <Link to='/gallery'>
                    <li onClick={props.clickHandler}>GALLERY</li>
                </Link>
                <Link to='/about'>
                    <li onClick={props.clickHandler}>ABOUT US</li>
                </Link>
                <Link to='/testimonial'>
                    <li onClick={props.clickHandler}>TESTIMONIALS</li>
                </Link>
                <Link to='/contact'>
                    <li onClick={props.clickHandler}>CONTACT US</li>
                </Link>
            </ul>
        </nav>
    );
}

export default SideDrawer;