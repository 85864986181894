import React from 'react';

import './HamburgerButton.scss';

interface Props {
    hamburgerClicked: (() => void),
}

function HamburgerButton(props:Props) {
    return (
        <button className="hamburgerButton" onClick={props.hamburgerClicked}>
            <div></div>
            <div></div>
            <div></div>
        </button>
    );
}

export default HamburgerButton;