import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import './Footer.scss';

function Footer() {
    return (
        <div className='footerContainer'>
            <div className="spacerContent"></div>
            <div className="copyrightText">
                <p>authentic design concepts, Copyright &copy; 2020</p>
            </div>
            <div className='socialButtons'>
                <a href='https://www.facebook.com/Authentic-Design-Concepts-1982701655292082' target="_blank" rel="noopener noreferrer" className='facebook social'>
                    <FontAwesomeIcon icon={faFacebook} size='2x' />
                </a>
                <a href='https://www.instagram.com/authenticdesignconcepts/' target="_blank" rel="noopener noreferrer" className='instagram social'>
                    <FontAwesomeIcon icon={faInstagram} size='2x' />
                </a>
            </div>
        </div>
    );
}

export default Footer;