import React from 'react';

import './Backdrop.scss';

interface Props {
    clickHandler: (() => void),
}

function Backdrop(props:Props) {
    return (
        <div className="backdrop" onClick={props.clickHandler}></div>
    );
}

export default Backdrop;