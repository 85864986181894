import React from 'react';
import { useState, useEffect } from 'react';

import './Gallery.scss';

//Listen for screen size changes...
const getWidth = () => window.innerWidth 
  || document.documentElement.clientWidth 
  || document.body.clientWidth;

function useCurrentWidth() {
  let [width, setWidth] = useState(getWidth());

  useEffect(() => {
    let timeoutId:number|undefined = undefined;
    const resizeListener = () => {
      clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => setWidth(getWidth()), 10);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    }
  }, [])

  return width;
}

function Gallery() {
    let width = useCurrentWidth();

    //Map over the images directory and grab all the paths...
    function importAll(r: any) {
        return r.keys().map(r);
    }
    const images = importAll(require.context('../../images/gallery', false, /\.(png|jpe?g|svg)$/));

    let listItems = images.map((item:any, index:number) => {
        //If mobile we have less screen space...
        if (width <= 480) {
            return (
                <div key={index} className="item">
                    <img src={item} alt="" width="300" height="250"></img>
                </div> 
            );
        }
        return (
            <div key={index} className="item">
                <img src={item} alt="" width="400" height="350"></img>
            </div>
        );
    });
    return (
        <div className="galleryContainer">
            <div className="container">
                {listItems}
            </div>
        </div>
    );
}

export default Gallery;