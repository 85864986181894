import React from 'react';
import { Link } from 'react-router-dom';

import Carousel from '../Carousel/Carousel';
import './Homepage.scss';

function Homepage() {
    return (
        <div className="contentContainer">
            <div className="wrapper">
                <div className='backgroundImage'></div>
                <div className='textContainer'>
                    <h1>authentic design concepts</h1>
                    <h2>quality timber solutions</h2>
                </div>
            </div>
            <div className="textContent">
                <div className="textWrapper">
                    <p>
                    authentic design concepts is a small family owned business located in Toowoomba, Queensland, Australia.
                    What started as a hobby in 1997 turned into a business focused on craftsmanship, attention to detail and our ability to bring your vision to life. 
                    Check out our range of custom products and designs and feel free to contact us to discuss further.
                    </p>
                    <Link to="/contact">
                        <div className="containerContactBtn">
                            <button className='homepageContactBtn'>CONTACT US</button>
                        </div>
                    </Link>
                </div>
            </div>
            <Carousel />
        </div>
    );
}

export default Homepage;