import React from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt, faEnvelope, faSpinner } from '@fortawesome/free-solid-svg-icons';

import './ContactForm.scss';

interface Props {
}

interface State {
  name: string;
  email: string;
  message: string;
  useSpinner: boolean;
  responseMessage: {
    type: string,
    message: string,
  };
}

class ContactForm extends React.Component<{}, State> {
  constructor(props:Props) {
    super(props);
    this.state = {
      name: '',
      email: '',
      message: '',
      useSpinner: false,
      responseMessage: {
        type: '',
        message: '',
      },
    }
  }

  handleSubmit(e:any) {
    e.preventDefault();
    this.setState({
      useSpinner: true,
      responseMessage: {
        type: '',
        message: '',
      }
    });

    axios({
      method: "POST", 
      url:"/contactFormHandler.php", 
      data:  this.state,
      timeout: 10000,
    }).then((response:any) => {
      if (response.data.status === 'success'){
        this.setState({
          useSpinner: false,
          responseMessage: {
            type: 'success',
            message: 'Message Sent',
          },
        });
        this.resetForm()
      } else if (response.data.status === 'fail'){
        this.setState({
          useSpinner: false,
          responseMessage: {
            type: 'fail',
            message: `${response.data.error}`,
          },
        });
      }
    }).catch((response:any) => {
      this.setState({
        useSpinner: false,
        responseMessage: {
          type: 'fail',
          message: `${response.message}: If the problem persists please try our 'Enquiries' number/email, thankyou.`,
        },
      });
    });
  }

  resetForm() {
      this.setState({name: '', email: '', message: ''});
      // @ts-ignore
      //gets rid of the red borders when the form is reset after successful submit.
      document.getElementById("contactForm").reset();
  }

  onFormChange(event: any, type: string) {
    if (type === 'Name') {
      this.setState({name: event.target.value})
    } else if (type === 'Email') {
      this.setState({email: event.target.value})
    } else if (type === 'Message') {
      this.setState({message: event.target.value})
    }
  }

  render() {
    return(
      <div className="contactFormContainer">
        <div className='formContainer'>
          <form id='contactForm' onSubmit={this.handleSubmit.bind(this)} method="POST">
            <h1 id='contactHeader'>CONTACT US</h1>
            <div className="formRow">
              <div className="formItem splitRow">
                <label htmlFor="name">Name</label>
                <input 
                  type="text" 
                  className="formData" 
                  id="name" 
                  value={this.state.name} 
                  maxLength={100}
                  onChange={(e) => this.onFormChange(e, 'Name')}
                  required
                />
              </div>
              <div className="formItem splitRow">
                <label htmlFor="email">Email</label>
                <input 
                  type="email" 
                  className="formData" 
                  id="email" 
                  aria-describedby="emailHelp" 
                  value={this.state.email} 
                  maxLength={255}
                  onChange={(e) => this.onFormChange(e, 'Email')} 
                  required
                />
              </div>
            </div>
            <div className="formRow">
              <div className="formItem">
                <label htmlFor="message">Message</label>
                <textarea 
                  className="formData messageField" 
                  rows={5} 
                  id="message" 
                  value={this.state.message} 
                  maxLength={1500}
                  onChange={(e) => this.onFormChange(e, 'Message')}
                  required
                />
              </div>
            </div>
            {this.state.responseMessage.type !== '' &&
              <div 
                className={`responseMessage ${this.state.responseMessage.type === 'success' ? 'successMessage' : 'errorMessage'}`}
              >{this.state.responseMessage.message}</div>
            }
            <button 
              type="submit" 
              className="submitButton" 
              // Used to reset the message on the form prior to validation.
              onClick={() => this.setState({responseMessage: {type:'',message:'',}})}
            >Send Message</button>
            {this.state.useSpinner === true &&
              <FontAwesomeIcon className="fa-spin customSpinner" icon={faSpinner} size='lg' />
            }
          </form>
        </div>
        <div className='enquiriesContainer'>
          <h1>ENQUIRIES</h1>
          <div className='enquiriesRow'>
            <div className="iconContainer">
              <FontAwesomeIcon className="mobileIcon" icon={faMobileAlt} size='2x' />
            </div>
            <p>Craig: 0428 435 222</p>
          </div>
          <div className='enquiriesRow'>
            <div className="iconContainer">
              <FontAwesomeIcon className="emailIcon" icon={faEnvelope} size='2x' />
            </div>
            <p>enquiries@authenticdesignconcepts.com</p>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactForm;