import React, { Component } from 'react';
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';

import Navbar from './components/Navbar/Navbar';
import SideDrawer from './components/SideDrawer/SideDrawer';
import Backdrop from './components/Backdrop/Backdrop';
import Homepage from './components/Homepage/Homepage';
import Gallery from './components/Gallery/Gallery';
import Contact from './components/Contact/Contact';
import About from './components/About/About';
import Footer from './components/Footer/Footer';

import './App.scss';
import Testimonial from './components/Testimonial/Testimonial';

interface State {
  sideDrawerOpen: boolean;
}

class App extends Component<{}, State> {
  state = {
    sideDrawerOpen: false,
  };

  hamburgerToggleHandler = () => {
    this.setState((prevState:State) => {
      return {sideDrawerOpen: !prevState.sideDrawerOpen};
    });
  };

  backdropClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

  sideDrawerClickHandler = () => {
    this.setState({sideDrawerOpen: false});
  };

  render() {
    let backdrop;

    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop clickHandler={this.backdropClickHandler}/>;
    }
    return (
      <Router>
        <div className="appContainer">
          <main>
            <Navbar hamburgerHandler={this.hamburgerToggleHandler}/>
            <SideDrawer 
              show={this.state.sideDrawerOpen} 
              clickHandler={this.sideDrawerClickHandler}
            />
            {backdrop}
            <Switch>
              <Route path="/" exact component={Homepage}/>
              <Route path="/testimonial" component={Testimonial}/>
              <Route path="/gallery" component={Gallery}/>
              <Route path="/about" component={About}/>
              <Route path="/contact" component={Contact}/>
            </Switch>
            <Footer />
          </main>
        </div>
      </Router>
    );
  }
}

export default App;
