import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar } from '@fortawesome/free-solid-svg-icons';

import './Testimonial.scss';

function Testimonial() {
    const testimonials = [
        {
            // eslint-disable-next-line
            quote: "\"Fantastic experience with authentic design concepts*. Custom made desk for my daughter. \
                Completed job quickly, fair price and a high standard, we love it and can highly \
                recommend. Very good communication and willing to do custom work- awesome 👏\"",
            author: "Allison Dobbelaar",
            image: "allisonDobbelaarDesk.jpg",
            imageAlt: "White Desk"
        },
        {
            // eslint-disable-next-line
            quote: "\"Wow is all I can say, these guys was an absolute pleasure to deal with, \
                the Barn door we ordered was delivered on time and looking the goods, after install \
                and paint job it look a million $$ thanks guys 👍\"",
            author: "Clive Chappell",
            image: "cliveChappelDoor.jpg",
            imageAlt: "Barn Door"
        },
        {
            // eslint-disable-next-line
            quote: "\"I\’ve been dreaming of putting a door on my media room since we moved in. \
            Authentic Designs helped me with that from finish to end and I couldn’t be any happier. \
            It had to be custom made due to sizes. They even looked into different types of wood to \
            match the existing we had in already, all with a smile.\
            The finished product is beautiful, easy to install and makes me smile every time I walk by!\
            For a very competitive price.\
            Thanks again so much!!\"",
            author: "Corinne Hamalainen-Tristram",
            image: "corinneHamalainen-Tristram.jpg",
            imageAlt: "Large Barn Door"
        },
        {
            // eslint-disable-next-line
            quote: "\"Beautifully presented, quality wood chopping board. \
            The four cushioned feet on the board were a great surprise! \
            Excellent, prompt and personalised customer service. \
            I would recommend this product as a gift to anyone.\"",
            author: "Lucy Young",
            image: "lucyYoungChoppingBoard.jpg",
            imageAlt: "Chopping Board"
        }, 
    ];

    let renderTestimonial = testimonials.map((t:any, index:number) => {
        if (t.image !== "") {
            return (
                <div className="testimonialContainer">
                    <div className="testimonialQuote">
                        <div style={{ maxHeight: '60%', overflow: 'auto', lineHeight: '1.5'}}>
                            {t.quote}
                        </div>
                        <span className="authorContainer">{t.author}</span>
                        <span className="starsContainer">
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                        </span>
                    </div>
                    <div className="testimonialImage">
                        <img src={require(`../../images/testimonials/${t.image}`)} alt={t.imageAlt}></img>
                    </div>
                </div>
            );
        } else {
            return (
                <div className="testimonialContainer">
                    <div className="testimonialQuote">
                        <p>
                            {t.quote}
                        </p>
                        <span className="authorContainer">{t.author}</span>
                        <span className="starsContainer">
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                            <FontAwesomeIcon className="star" icon={faStar} size='1x' />
                        </span>
                    </div>
                    <div className="imageSpacer">
                        <div className="emptyImgSpacer">

                        </div>
                    </div>
                </div>
            );
        }
    });
    return (
        <div className="testimonialContentContainer">
            {renderTestimonial}
        </div>
    );
}

export default Testimonial;