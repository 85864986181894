import React from 'react';

import DesignerPicture from '../../images/craig.jpg'
import './About.scss';

function About() {
    return (
        <div className='aboutContainer'>
            <h4>About the Business</h4>
            <p>
                Authentic design concepts is a small family owned business located in Toowoomba, Queensland, Australia. 
                We design all our furniture in house and customise any build to suit our customer’s needs. 
                As a bespoke timber furniture business that creates unique modern farmhouse, and rustic furniture designs focused on simplistic design, quality and affordability.
                We pride ourselves in constructing our products from sustainably sourced pine timber with hardwood timber accents.
            </p>
            <div className='designerSection'>
                <img src={DesignerPicture} alt="Designer" width="300" height="300"/>
                <div>
                    <h4>About the Craftsmen</h4>
                    <p>
                        Craig Oldfield, the owner and craftsmen behind authentic design concepts has been building timeless, custom pieces since 1997. 
                        As a veteran of the Australian Defence Force, Craig brings an old fashion approach to business with his hardworking nature and integrity that has followed him through his
                        military career into his business life. 
                        Each piece of custom furniture is constructed by hand in our work shop, and the furniture is hand painted to ensure we capture the elegant and timeless farmhouse style.
                        Craig is a qualified draftsmen with extensive experience in building design and home renovation, with these skills he works closely with each client to make their vision
                        a reality. 
                    </p>
                </div>
            </div>
        </div>
    );
}

export default About;