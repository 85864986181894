import React from 'react';
import ContactForm from './ContactForm';

import './Contact.scss';

function Contact() {
    return (
        <div className="contactContentContainer">
            <ContactForm />
        </div>
    );
}

export default Contact;