import React from 'react';
import { Link } from 'react-router-dom';

import HamburgerButton from '../SideDrawer/HamburgerButton';
import Logo from '../../images/logo/logo_white_cropped.png'

import './Navbar.scss';

interface Props {
    hamburgerHandler: (() => void),
}

function Navbar(props:Props) {
    return (
        <header className="navbar">
            <nav className="navbarNavigation">
                <div className="navBarHamburgerButton">
                    <HamburgerButton hamburgerClicked={props.hamburgerHandler}/>
                </div>
                <div className="navbarLogo">
                    <Link to="/">
                        <img src={Logo} alt="Logo" width="200" />
                    </Link>
                </div>
                <div className="navSpacer"></div>
                <div className="navbarNavigationItems">
                    <ul>
                        <Link to='/gallery'>
                            <li>GALLERY</li>
                        </Link>
                        <Link to='/about'>
                            <li>ABOUT US</li>
                        </Link>
                        <Link to='/testimonial'>
                            <li>TESTIMONIALS</li>
                        </Link>
                        <Link to='/contact'>
                            <li>CONTACT US</li>
                        </Link>
                    </ul>
                </div>
            </nav>
        </header>
    );
}

export default Navbar;